<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card no-body>
          <b-card-header>
            <b-row class="w-100">
              <b-col
                cols="12"
                md="12"
              >
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    placeholder="Müşteri Adı, Araç Plakası..."
                    @keydown.enter="searchData"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="primary"
                      @click="searchData"
                    >
                      <FeatherIcon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </b-row>
          </b-card-header>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
            hover
          >
            <template #cell(customer)="data">
              <a :href="'/car/'+data.item.license_plate+'/'+data.item.id">
                {{ data.item.swap_brand }} {{ data.item.swap_model }} {{ data.item.hardware }}
                <div>
                  <b-badge
                    variant="light-info mr-1"
                  >
                    <feather-icon icon="UserIcon" />
                    {{ data.item.company_name ? data.item.company_name : data.item.customer }}
                  </b-badge>
                  <b-badge variant="light-primary mt-1 mr-1">
                    <feather-icon icon="CreditCardIcon" />
                    {{ data.item.license_plate }}
                  </b-badge>
                  <b-badge variant="light-warning mt-1 mr-1">
                    <feather-icon icon="CalendarIcon" />
                    {{ moment(data.item.created).format('DD.MM.YYYY') }}
                  </b-badge>
                  <b-badge variant="light-secondary mt-1">
                    <feather-icon icon="SettingsIcon" />
                    Model yılı {{ data.item.model_year }}
                  </b-badge>
                </div>
              </a>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination></b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BCardFooter,
  BPagination,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BCardHeader,
  BBadge,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BBadge,
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardHeader,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'customer',
          label: 'Araç',
        },
      ],
      dataQuery: {
        select: [
          'com_swapcar.id AS id',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_swap_brand.name AS swap_brand',
          'com_swap_model.title AS swap_model',
          'com_swapcar.edate AS edate',
          'com_swapcar.hardware AS hardware',
          'com_swapcar.license_plate AS license_plate',
          'com_swapcar.model_year AS model_year',
          'com_swapcar.id_com_swap_brand AS id_com_swap_brand',
          'com_swapcar.id_com_swap_model AS id_com_swap_model',
          'com_user.name AS username',
          'user_request.name AS user_request',
        ],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['swapcars/getSwapcars']
    },
    dataCount() {
      return this.$store.getters['swapcars/getSwapcarsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    searchData() {
      this.dataQuery.or_like = {
        'com_customer.name': this.search,
        'com_customer.company_name': this.search,
        'com_customer.phone': this.search,
        'com_swapcar.license_plate': this.search,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('swapcars/swapcarsList', this.dataQuery)
    },
  },
}
</script>
